
    var riot = require('riot')
    
riot.tag2('heightcalc', '<yield></yield>', '', '', function(opts) {
this.on('mount', (function(_this) {
  return function() {
    _this.heightcalc();
    return window.onresize = _this.heightcalc;
  };
})(this));

this.heightcalc = (function(_this) {
  return function() {
    var doch, h;
    doch = document.querySelector('body').getBoundingClientRect().width;
    h = _this.root.getBoundingClientRect().height;
    if (doch > 768) {
      return _this.root.style.marginTop = "-" + parseInt(h / 2) + "px";
    } else {
      return _this.root.style.marginTop = "auto";
    }
  };
})(this);
});
    
  