window.riot = riot = require 'riot'
require 'riot-kit/lib/slideshow'
require "riot-kit/lib/img_loader"
require 'riot-kit/lib/form'
require 'riot-kit/lib/twitter'
require 'riot-kit/lib/modal'
require 'riot-kit/lib/form'
require 'riot-kit/lib/ecommerce'
require 'riot-kit/lib/pagination'
require 'riot-kit/lib/dateformat'
require 'riot-kit/lib/booking-calendar'
require 'riot-kit/lib/dropdown'
require 'riot-kit/lib/gmap'
require('riot-kit/lib/auth/forgot-password')

require './tags/loginbar.tag'
require './tags/ecommercebar.tag'
require './tags/navigation.tag'
require './tags/mydetails.tag'
require './tags/mypassword.tag'
require './tags/signup.tag'
require './tags/tags-input.tag'
require './tags/comments_input.tag'
require './tags/comments_output.tag'
require './tags/recaptcha.tag'
require './tags/pricetag.tag'
require './libs/slideshow.coffee'
require './tags/eventcalendar.tag'
require './tags/heightcalc.tag'

require './tags/videomodal.tag'

require './tags/ytmodal.tag'
require './tags/ical.tag'

riot.mount('*')
document.addEventListener 'duocms:updated', -> riot.mount('slideshow,gmap')

vidlinks = document.querySelectorAll('.figure-videolink')
if vidlinks
  console.log vidlinks
  [].map.call vidlinks, (vidlink)=>
    link = vidlink.querySelector('a[href]')
    if link
      console.log link
      vidlink.addEventListener 'click',(e)=>
        console.log "click"
        e.preventDefault()
        link.click()

ctas = document.querySelectorAll('.cta-inner')
if ctas && ctas.length > 0
  [].map.call ctas, (cta)=>
    link = cta.querySelector('a[href]')
    if link
      cta.addEventListener 'click',(e)=>
        link.click()

# footer = document.querySelector('.footer')
# body = document.querySelector('body')
# footerbox = footer.getBoundingClientRect()
# if footerbox && footerbox.height
#   body.style.paddingBottom = footerbox.height+"px"

# # mobile nav stuff
# mmutationObserver = window.MutationObserver || window.WebKitMutationObserver || window.MozMutationObserver
# mo = new mmutationObserver (mutations)->
#   m = 0
#   # mutations.forEach (mutation)->
#   while m < mutations.length
#     mutation = mutations[m]
#     if mutation.type == "attributes" && mutation.target.className.match(/navshowing/)
#       [].slice.call(document.querySelectorAll(".navbar-nav li")).forEach (subnav)->
#         subnav.addEventListener 'click',(e)->
#           if subnav.getAttribute('riot-tag') == "dropdown" && subnav.className.match(/open/)
#             e.preventDefault()
#             subnav.querySelector('a,i').addEventListener 'click',(e)->
#               window.location.href = subnav.querySelector('a').href
#             subsecs = subnav.querySelectorAll('.dropdown-menu a')
#             if subsecs.length > 0
#               [].slice.call(subsecs).map (sec)=>
#                 sec.addEventListener 'click',(e)->
#                   window.location.href = sec.href
#     m++

# mo.observe(document.querySelector('body'),{attributes:true})


# intersection observer
ithreshold = 0.05
window.observerCallback = (entries, observer) ->
  i = 0
  while i < entries.length
    entry = entries[i]
    if entry.intersectionRatio > ithreshold
      entry.target.className = entry.target.className.replace(RegExp(/ ?show ?/), '') + ' show'
      observer.unobserve entry.target
    i++
  return

options = threshold: ithreshold
observer = new IntersectionObserver(observerCallback, options)
targets = document.querySelectorAll('.intersect')
t = 0
if !document.querySelector('body').className.match(/editing/)
  while t < targets.length
    observer.observe targets[t]
    t++


window.checkscroll = ->
  body = document.querySelector('body')
  navbar = document.querySelector('.navbar-default')
  bodyYpos = body.getBoundingClientRect().top
  bodyWidth = body.getBoundingClientRect().width

  if Math.abs(bodyYpos) > 160
    navbar.className = navbar.className.replace(/ ?scrolled ?/,'')+' scrolled '
  else
    navbar.className = navbar.className.replace(/ ?scrolled ?/,'')

['scroll','load','resize'].forEach (type)->
  window.addEventListener type,checkscroll
