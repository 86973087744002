
    var riot = require('riot')
    require('riot-kit/lib/datepicker');require('riot-kit/lib/modal');require('riot-kit/lib/form');require('riot-kit/lib/formdate');require('riot-kit/lib/confirm');require('riot-kit/lib/dateformat')
riot.tag2('eventcalendar', '<div class="{opts.fullpage ? \'row\' : \'\'}"> <div class="{opts.fullpage ? \'col-xs-12 col-md-4\' : \'\'}"> <datepicker if="{!opts.hidecalendar}" date="{new Date()}" onupdate="{setDate}" datestyle="{handleDateStyle}"></datepicker> </div> <div class="{opts.fullpage ? \'col-xs-12 col-md-8\' : \'\'}"> <h4 if="{!opts.hidetitle}" class="list-title">{listTitle || \'Upcoming Events\'}</h4> <eventlist events="{daysEvents || events}" viewevent="{handleViewEvent}" limit="{showUpcoming ? limit : 0}" current="{currentdate}" if="{!opts.wideview}"></eventlist> <eventlist-wide events="{daysEvents || events}" viewevent="{handleViewEvent}" limit="{showUpcoming ? limit : 0}" current="{currentdate}" if="{opts.wideview}"></eventlist-wide> <div if="{window.location.search.search(/cms=/) &gt; -1}"> <div style="margin:0;" class="row"> <button onclick="{handleNewEvent}" class="pull-right btn btn-primary addevent">Add Event</button> </div> </div> </div> </div> <modal name="eventmodal" title="Event Details" onclose="{true}" onsave="{mode==\'edit\' ? save : null}"> <eventcalendarform show="{parent.mode==\'edit\'}" eventdata="{parent.eventdata}" onupdate="{parent.handleUpdate}"></eventcalendarform> <eventdetails show="{parent.mode!=\'edit\'}" eventdata="{parent.eventdata}" ondelete="{parent.handleDelete}" onmodechange="{parent.handleChangeMode}"></eventdetails> </modal>', 'eventcalendar { display: block; } .datepicker .subtle { color: #ccc; } .datepicker th, .datepicker td { text-align: center; } .datepicker .event { font-weight: bold; } eventcalendarform .nav > li a, eventcalendarform .nav > li.active a { color: #1b438b !important; }', '', function(opts) {
var Calendar, Formatter, eventResource, resource;

Calendar = require('riot-kit/utils/calendar.coffee');

Formatter = require('riot-kit/utils/dateformat.coffee');

resource = require('riot-kit/utils/apiResource.coffee');

this.listTitle = null;

this.daysEvents = null;

this.showUpcoming = true;

eventResource = new resource(opts.src || '/duocms/api/events');

this.currDate = new Date();

this.prevUrl = '';

this.mode = 'show';

this.activeDates = [];

this.datemode = "upcoming";

this.on('mount', (function(_this) {
  return function() {
    _this.loadUpcomingEvents();
    return document.addEventListener('duocms:updated', function() {
      return _this.update();
    });
  };
})(this));

this.setDate = (function(_this) {
  return function(date, changetypes) {
    if (changetypes.indexOf('month') > -1 || changetypes.indexOf('year') > -1) {
      _this.currDate = new Date(date);
      return _this.loadMonthsEvents();
    } else if (changetypes.indexOf('date') > -1) {
      _this.currDate = new Date(date);
      return _this.loadDaysEvents();
    }
  };
})(this);

this.loadUpcomingEvents = function() {
  var end, endDate, endDays, endMonth, endYear, start, startDate, startDay, startMonth, startYear;
  this.datemode = "upcoming";
  this.showUpcoming = true;
  this.listTitle = "Upcoming Events";
  this.currentdate = null;
  startYear = new Date().getFullYear();
  startMonth = new Date().getMonth();
  startDay = 1;
  endYear = new Date().getFullYear();
  endMonth = new Date().getMonth() + 2;
  endDays = new Date(endYear, endMonth + 1, 0).getDate();
  startDate = new Date(startYear, startMonth, startDay);
  endDate = new Date(endYear, endMonth, endDays, 23, 59, 59);
  start = new Formatter(startDate).format('yyyy-M-d');
  end = new Formatter(endDate).format('yyyy-M-d HH:mm:ss');
  this.limit = opts.limit || 4;
  return eventResource.get({
    start: start,
    end: end
  }, (function(_this) {
    return function(res) {
      _this.daysEvents = null;
      _this.events = res;
      _this.createActiveDates();
      return _this.update();
    };
  })(this));
};

this.loadMonthsEvents = function() {
  var daysInMonth, end, endDate, month, start, startDate, year;
  this.datemode = "monthly";
  this.showUpcoming = false;
  this.currentdate = new Date(this.currDate).setDate(1);
  this.listTitle = new Formatter(this.currDate).format('MMMM') + "'s Events";
  year = this.currDate.getFullYear();
  month = this.currDate.getMonth();
  daysInMonth = new Date(year, month + 1, 0).getDate();
  startDate = new Date(year, month, 1);
  endDate = new Date(year, month, daysInMonth, 23, 59, 59);
  start = new Formatter(startDate).format('yyyy-M-d');
  end = new Formatter(endDate).format('yyyy-M-d HH:mm:ss');
  return eventResource.get({
    start: start,
    end: end
  }, (function(_this) {
    return function(res) {
      _this.daysEvents = null;
      _this.events = res;
      _this.createActiveDates();
      return _this.update();
    };
  })(this));
};

this.loadDaysEvents = function() {
  var calendar, ref;
  this.datemode = "daily";
  this.showUpcoming = false;
  this.currentdate = this.currDate;
  this.listTitle = "Events for " + new Formatter(this.currDate).format('d MMMM');
  this.daysEvents = [];
  calendar = new Calendar(this.currDate);
  if ((ref = this.events) != null) {
    ref.forEach((function(_this) {
      return function(evt) {
        var d;
        d = _this.findClosest(evt.dates, _this.currDate);
        if (calendar.isToday(d)) {
          return _this.daysEvents.push(evt);
        }
      };
    })(this));
  }
  return this.update();
};

this.findClosest = (function(_this) {
  return function(dateArray, currentDate) {
    var date, i, len;
    if (!dateArray) {
      return null;
    }
    for (i = 0, len = dateArray.length; i < len; i++) {
      date = dateArray[i];
      if (date >= currentDate) {
        return date;
      }
    }
  };
})(this);

this.handleUpdate = (function(_this) {
  return function(eventdata) {
    return _this.eventdata = eventdata;
  };
})(this);

this.handleDelete = (function(_this) {
  return function(eventdata) {
    return eventResource.remove({
      id: eventdata.id
    }, function(res) {
      _this.tags.eventmodal.hide();
      return _this.loadUpcomingEvents();
    });
  };
})(this);

this.handleNewEvent = (function(_this) {
  return function() {
    _this.eventdata = {
      id: null,
      name: '',
      description: '',
      link: '',
      publish: false,
      event_times: [
        {
          id: null,
          start: new Date(),
          end: new Date(),
          repeat: ''
        }
      ]
    };
    _this.mode = 'edit';
    _this.update();
    return setTimeout(function() {
      _this.tags.eventmodal.show();
      return riot.update();
    });
  };
})(this);

this.handleDateStyle = (function(_this) {
  return function(date) {
    if (_this.activeDates.indexOf(date.getTime()) > -1) {
      return 'event';
    }
    return '';
  };
})(this);

this.createActiveDates = (function(_this) {
  return function() {
    var calendar;
    _this.activeDates = [];
    calendar = new Calendar();
    _this.events.forEach(function(event, index) {
      _this.events[index].dates = [];
      return event.event_times.forEach(function(time) {
        var repeatDates;
        repeatDates = calendar.repeat(time.start, time.end, time.repeat);
        _this.events[index].dates = _this.events[index].dates.concat(repeatDates);
        return _this.activeDates = _this.activeDates.concat(repeatDates);
      });
    });
    return _this.activeDates = _this.activeDates.map(function(date) {
      return date.setHours(0, 0, 0, 0);
    });
  };
})(this);

this.handleChangeMode = (function(_this) {
  return function(mode) {
    _this.tags.eventmodal.hide();
    _this.update();
    return setTimeout(function() {
      _this.mode = mode;
      _this.update();
      _this.tags.eventmodal.show();
      return _this.update();
    }, 250);
  };
})(this);

this.handleViewEvent = (function(_this) {
  return function(event) {
    return eventResource.get({
      id: event.id
    }, function(res) {
      res.event_times.forEach(function(time) {
        time.starttime = new Formatter(time.start).format('HH:mm');
        return time.endtime = new Formatter(time.end).format('HH:mm');
      });
      _this.eventdata = res;
      _this.mode = 'show';
      _this.update();
      return _this.tags.eventmodal.show();
    });
  };
})(this);

this.save = (function(_this) {
  return function() {
    _this.eventdata.event_times.forEach(function(time) {
      delete time.starttime;
      return delete time.endtime;
    });
    return eventResource.save(_this.eventdata, function(res) {
      _this.tags.eventmodal.hide();
      return _this.loadUpcomingEvents();
    });
  };
})(this);
});
riot.tag2('eventcalendarform', '<div class="form-horizontal"> <forminput label="Event Title" name="name" value="{event.name}" onupdate="{handleUpdate}"></forminput> <formtextarea label="Event Description" value="{event.description}" name="description" rows="6" onupdate="{handleUpdate}"></formtextarea> <forminput label="Link" name="link" value="{event.link}" onupdate="{handleUpdate}"></forminput> <formcheckbox label="Publish" name="publish" value="{event.publish}" onupdate="{handleUpdate}"></formcheckbox> <hr> <h4>Date Ranges</h4> <ul class="nav nav-tabs"> <li each="{range,index in event.event_times}" class="{active:parent.dateRangeChosen==index}"> <a onclick="{parent.setRange}">{index+1}</a></li> <li><a onclick="{addRange}">Add</a></li> <li><a if="{event.event_times.length&gt;1}" riot-tag="confirm" onconfirm="{removeRange}">Remove Selected</a></li> </ul><br> <eventtimes each="{range,index in event.event_times}" range="{range}" index="{index}" onupdate="{parent.handeDateUpdate}" if="{parent.dateRangeChosen==index}"></eventtimes> </div>', '', '', function(opts) {
this.dateRangeChosen = 0;

this.on('update', function() {
  return this.event = opts.eventdata;
});

this.handleUpdate = (function(_this) {
  return function(field) {
    _this.event[field.name] = field.value;
    return opts.onupdate(_this.event);
  };
})(this);

this.handeDateUpdate = (function(_this) {
  return function(event_time, index) {
    return _this.event.event_times[index] = event_time;
  };
})(this);

this.addRange = (function(_this) {
  return function() {
    _this.event.event_times.push({
      start: null,
      end: null,
      repeat: null
    });
    return _this.update();
  };
})(this);

this.setRange = (function(_this) {
  return function(e) {
    _this.dateRangeChosen = e.item.index;
    return _this.update();
  };
})(this);

this.removeRange = (function(_this) {
  return function(e) {
    _this.event.event_times.splice(_this.dateRangeChosen, 1);
    if (_this.dateRangeChosen >= _this.event.event_times.length) {
      _this.dateRangeChosen = _this.event.event_times.length - 1;
    }
    return _this.update();
  };
})(this);
});
riot.tag2('eventtimes', '<formdate label="Start Date" name="startdate" value="{time.start}" dateformat="dd/MM/yyyy" onupdate="{handleUpdate}"></formdate> <formdate label="End Date" name="enddate" value="{time.end}" dateformat="dd/MM/yyyy" onupdate="{handleUpdate}"></formdate> <forminput label="Start Time" name="starttime" value="{time.starttime}" placeholder="HH:MM" onupdate="{handleUpdate}"></forminput> <forminput label="End Time" name="endtime" value="{time.endtime}" placeholder="HH:MM" onupdate="{handleUpdate}" type="{\'time\'}"></forminput> <formselect label="Repeat " name="repeat" value="{time.repeat}" default="Please Select" onupdate="{handleUpdate}" options="{[{value:\'none\',label:\'None\'},{value:\'daily\',label:\'Daily\'},{value:\'weekly\',label:\'Weekly\'},{value:\'fortnightly\',label:\'Fortnightly\'},{value:\'monthly\',label:\'Monthly\'}]}" type="{\'time\'}"></formselect>', '', '', function(opts) {
this.on('update', function() {
  return this.time = opts.range;
});

this.handleUpdate = (function(_this) {
  return function(field) {
    if (field.name === 'startdate') {
      _this.time.start = _this.copyDate(field.value, _this.time.start);
    }
    if (field.name === 'enddate') {
      _this.time.end = _this.copyDate(field.value, _this.time.end);
    }
    if (field.name === 'starttime') {
      _this.time.start = _this.copyTime(field.value, _this.time.start);
    }
    if (field.name === 'endtime') {
      _this.time.end = _this.copyTime(field.value, _this.time.end);
    }
    if (field.name === 'repeat') {
      _this.time.repeat = field.value;
    }
    return opts.onupdate(_this.time, opts.index);
  };
})(this);

this.copyDate = (function(_this) {
  return function(from, to) {
    if (!to) {
      to = new Date(1, 1, 1, 0, 0, 0);
    }
    to.setFullYear(from.getFullYear());
    to.setMonth(from.getMonth());
    to.setDate(from.getDate());
    return to;
  };
})(this);

this.copyTime = (function(_this) {
  return function(time, to) {
    var hms;
    hms = time.match(/([0-9]{1,2}):([0-9]{1,2})/);
    if (!to) {
      to = new Date(1, 1, 1, 0, 0, 0);
    }
    if ((hms != null ? hms.length : void 0) > 0) {
      to.setHours(hms[1]);
    }
    if ((hms != null ? hms.length : void 0) > 1) {
      to.setMinutes(hms[2]);
    }
    return to;
  };
})(this);
});
riot.tag2('eventlist', '<ul class="{events.length == 0 ? \'noevents\' : \'\'}"> <li if="{events == null}">Loading Events</li> <li if="{events.length == 0}">No Upcoming Events</li> <li each="{events}"><a href="#" onclick="{showmodal}">{name || \'Untitled Event\'}</a><br><small riot-tag="dateformat" format="dd/MM/yyyy" date="{next}"></small></li> </ul>', '', '', function(opts) {
this.showmodal = (function(_this) {
  return function(e) {
    return opts.viewevent(e.item);
  };
})(this);

this.events = [];

this.on('update', (function(_this) {
  return function() {
    var currDate;
    if (!opts.events) {
      return;
    }
    _this.events = [];
    currDate = opts.current || new Date();
    opts.events.forEach(function(evt, index) {
      evt.next = _this.nextDate(evt.dates, currDate);
      if (evt.next >= currDate) {
        return _this.events.push(evt);
      }
    });
    _this.events.sort(function(a, b) {
      return a.next - b.next;
    });
    if (opts.limit > 0) {
      return _this.events = _this.events.splice(0, opts.limit);
    }
  };
})(this));

this.nextDate = (function(_this) {
  return function(dateArray, currentDate) {
    var date, i, len;
    if (!dateArray) {
      return null;
    }
    for (i = 0, len = dateArray.length; i < len; i++) {
      date = dateArray[i];
      if (date > currentDate) {
        return date;
      }
    }
  };
})(this);
});
riot.tag2('eventlist-wide', '<ul class="row eventlist-wide {events.length == 0 ? \'noevents\' : \'\'}"> <li if="{events == null}">Loading Events</li> <li if="{events.length == 0}" class="col-xs-12"> <div class="row unpadded"> <div class="details"> <div class="title">No Upcoming Events</div> </div> </div> </li> <li each="{events}" onclick="{showmodal}" class="col-xs-12"> <div class="row unpadded"> <div riot-tag="dateformat" format="dd" date="{next}" class="bigdate"></div> <div class="details"> <div riot-tag="dateformat" format="MMMM" date="{next}" class="bigmonth"></div> <div class="title">{name || \'Untitled Event\'}</div> </div> </div> </li> </ul>', '', '', function(opts) {
this.showmodal = (function(_this) {
  return function(e) {
    return opts.viewevent(e.item);
  };
})(this);

this.events = [];

this.on('update', (function(_this) {
  return function() {
    var currDate, currMonth;
    if (!opts.events) {
      return;
    }
    _this.events = [];
    if (opts.current) {
      currDate = new Date(opts.current);
    } else {
      currDate = new Date();
    }
    currDate.setHours(0, 0, 0, 0);
    currMonth = currDate.getMonth();
    opts.events.forEach(function(evt, index) {
      if (_this.parent.datemode && _this.parent.datemode !== "daily") {
        return evt.dates.forEach(function(date, index) {
          var evtmonth, newevent;
          newevent = JSON.parse(JSON.stringify(evt));
          newevent.next = date;
          evtmonth = date.getMonth();
          if (_this.parent.datemode === "upcoming" && evtmonth >= currMonth) {
            if (newevent.next >= currDate) {
              _this.events.push(newevent);
            }
          }
          if (_this.parent.datemode === "monthly" && evtmonth === currMonth) {
            if (newevent.next >= currDate) {
              return _this.events.push(newevent);
            }
          }
        });
      } else {
        evt.next = _this.nextDate(evt.dates, currDate);
        if (evt.next >= currDate) {
          return _this.events.push(evt);
        }
      }
    });
    _this.events.sort(function(a, b) {
      return a.next - b.next;
    });
    if (opts.limit > 0) {
      return _this.events = _this.events.splice(0, opts.limit);
    }
  };
})(this));

this.nextDate = (function(_this) {
  return function(dateArray, currentDate) {
    var date, i, len;
    if (!dateArray) {
      return null;
    }
    for (i = 0, len = dateArray.length; i < len; i++) {
      date = dateArray[i];
      if (date >= currentDate) {
        return date;
      }
    }
  };
})(this);
});
riot.tag2('eventdetails', '<h2>{event.name}</h2> <p class="pre">{event.description}</p> <p if="{event.link}"><a href="{event.link}">Click here for more information</a></p> <div if="{event.event_times.length}"> <hr> <h4>Event Times</h4> <table class="table table-stiped"> <thead> <tr> <th>Start Date</th> <th>End Date</th> <th>Start Time</th> <th>End Time</th> <th>Repeats</th> </tr> </thead> <tbody> <tr each="{event.event_times}"> <td riot-tag="dateformat" date="{start}" format="d MMMM yyyy"></td> <td riot-tag="dateformat" date="{end}" format="d MMMM yyyy" if="{repeat &amp;&amp; repeat!=\'none\'}"></td> <td if="{!repeat || repeat==\'none\'}"></td> <td riot-tag="dateformat" date="{start}" format="HH:mm"></td> <td riot-tag="dateformat" date="{end}" format="HH:mm"></td> <td>{repeat}</td> </tr> </tbody> </table> <button if="{window.location.search.search(/cms=/) &gt; -1}" onclick="{editevent}" class="btn btn-success">Edit Event</button> <button if="{window.location.search.search(/cms=/) &gt; -1}" riot-tag="confirm" onconfirm="{deleteevent}" class="btn btn-danger">Delete Event</button> </div>', 'eventdetails .pre{ white-space: pre-wrap }', 'class="ed"', function(opts) {
this.on('update', function() {
  return this.event = opts.eventdata;
});

this.editevent = (function(_this) {
  return function() {
    return opts.onmodechange('edit');
  };
})(this);

this.deleteevent = (function(_this) {
  return function() {
    return opts.ondelete(_this.event);
  };
})(this);
});
    
  