
    var riot = require('riot')
    
riot.tag2('yt-modal', '<yield></yield><a onclick="{loadvideo}" class="vidbutton"></a> <modal name="modal1" onclose="{stopVid}" bssize="lg" if="{showvids}"> <div style="padding:56.3% 0 0 0;position:relative;"> <div id="ytframe"></div> </div> </modal>', '', '', function(opts) {
console.log("yt modal");

window.onYouTubeIframeAPIReady = (function(_this) {
  return function() {
    console.log("onYouTubeIframeAPIReady");
    _this.youtubeReady = true;
    return _this.setup();
  };
})(this);

this.ytid = this.opts.ytid || false;

this.player = null;

this.youtubeReady = false;

this.on('mount', (function(_this) {
  return function() {
    var firstScriptTag, scrtag;
    if (!window.location.search.match(/editmode/)) {
      _this.showvids = true;
      scrtag = document.createElement('script');
      scrtag.src = "https://www.youtube.com/iframe_api";
      firstScriptTag = document.getElementsByTagName('script')[0];
      firstScriptTag.parentNode.insertBefore(scrtag, firstScriptTag);
    }
    return _this.update();
  };
})(this));

this.stopVid = (function(_this) {
  return function() {
    if (_this.player) {
      _this.player.destroy();
      _this.player = null;
    }
    return _this.update();
  };
})(this);

this.loadvideo = (function(_this) {
  return function(e) {
    console.log("loadvideo");
    _this.tags.modal1.show();
    _this.setup();
    return _this.update();
  };
})(this);

this.setup = (function(_this) {
  return function() {
    console.log("iframe api ready");
    if (_this.player === null) {
      _this.player = new YT.Player(_this.tags.modal1.ytframe, {
        events: {
          'onReady': function() {
            return _this.onPlayerReady();
          },
          'onStateChange': _this.onPlayerStateChange,
          'onError': _this.vidError,
          'videoId': _this.ytid,
          'suggestedQuality': 'large'
        },
        playerVars: {
          'autoplay': 1,
          'rel': 0,
          'controls': 0,
          'showinfo': 0,
          'modestbranding': 1
        }
      });
    }
    return _this.update();
  };
})(this);

this.vidError = (function(_this) {
  return function(e) {
    return console.log("error", e);
  };
})(this);

this.onPlayerReady = (function(_this) {
  return function() {
    var error;
    console.log("player ready", _this.player);
    try {
      _this.player.cueVideoById({
        'videoId': _this.ytid,
        'suggestedQuality': 'large'
      });
      _this.player.playVideo();
    } catch (error1) {
      error = error1;
      console.log(error, _this.player);
      setTimeout(function() {
        _this.player.destroy();
        return _this.setup();
      }, 1000);
    }
    return _this.update();
  };
})(this);

this.onPlayerStateChange = (function(_this) {
  return function(e) {
    if (e.data === 0) {
      if (_this.player) {
        _this.player.destroy();
        _this.player = null;
      }
      _this.tags.modal1.close();
      return _this.update();
    }
  };
})(this);
});
    
  